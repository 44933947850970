const invalidClassName = 'input-invalid'
const inputEmail = document.querySelector('#email');
const inputPassword = document.querySelector('#password');
const data = {
    isFirstUnbluremail: false,
    isFirstUnblurpassword: false,
}

const validationService = (target) => {
    if(target.validity.valueMissing || target.validity.typeMismatch){
        target.classList.add(invalidClassName)
    }else{
        target.classList.remove(invalidClassName)
    }
}

const inputValidation = ({currentTarget}, key)=> {
    switch(key){
        case 'i':
            validationService(currentTarget) 
            break;
        case 'u':
            if(!data[`isFirstUnblur${currentTarget.id}`]){
                data[`isFirstUnblur${currentTarget.id}`] = true
                validationService(currentTarget)
            } 
            break
        default:
            break;
    }    
}

inputEmail.addEventListener('input', (event) => inputValidation(event, 'i'))
inputEmail.addEventListener('blur', (event) => inputValidation(event, 'u'))
inputPassword.addEventListener('input', (event) => inputValidation(event, 'i'))
inputPassword.addEventListener('blur', (event) => inputValidation(event, 'u'))